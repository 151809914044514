<!--
 * @Date: 2023-12-06 10:40:55
 * @Author: ym
 * @LastEditors: yanminw 2398988384@qq.com
 * @LastEditTime: 2024-04-19 17:02:32
 * @description: 
 * @FilePath: \yf_car\src\views\claimCoupon.vue
-->
<!--  -->
<template>
  <div id="g-bigvue-box" class="prepay claim-coupon">
    <i class="u-blue-bg"></i>
    <div class="g-white-box g-stop-payment">
      <p class="claim_title">绑定车牌</p>
      <img src="../assets/images/car-img.png" class="u-getinto-img" />
      <!-- <h3 class="u-inputcarid-tips">车牌后三位</h3> -->
      <keyboard :carNumAll.sync="carNumAll" ref="keyboard" v-if="!($route.query.is_auto == 1)" @keyboardStatu="keyboardStatu" :firstCheckbox="firstCheckbox" />
      <div id="keyboard">
        <!-- <div class="content-car-number">
          <div class="content-section">
            <div class="content-section-flex flex-card">
              <div class="content-section-flex flex-card-border">
                <button id="numFour" @click="btnClickNum('four')" v-bind:class="{ isNumClick: isNumFour }">
                  {{ numFour }}
                </button>
                <button id="numFive" @click="btnClickNum('five')" v-bind:class="{ isNumClick: isNumFive }">
                  {{ numFive }}
                </button>
                <button v-if="checkbox" id="numSix" @click="btnClickNum('six')" v-bind:class="{ isNumClick: isNumSix }">
                  {{ numSix }}
                </button>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div
          class="keyboard"
          :style="{ display: keyboardShow ? 'block' : 'block' }"
          :class="{ animationDown: isDown, animationUp: isUp }"
        >
          <button class="btn-complete" @click="completeClick"><span>完成</span></button>
          <div class="m-keyboard-box" v-if="keyboard == 'num'">
            <div class="keyboard-row" v-for="(item, rows) in carNum">
              <button
                :disabled="!isSelectl ? isDisable && i < 10 : isDisable && rows != 0"
                class="keyboard-row-item"
                v-for="(i, index) in item.name"
                @click="btnWordClick(rows, index, i)"
              >
                {{ i }}
              </button>
            </div>
            <div class="keyboard-row">
              <button
                :disabled="isSelectl"
                v-for="item in carNumBottom"
                class="keyboard-row-item bottom"
                @click="btnBottomNumClick(item)"
              >
                {{ item }}
              </button>
              <button v-for="j in noneBottom" class="keyboard-row-item none-botton">{{ j }}</button>
              <div class="keyboard-row-item clear" @click="clearClick">删</div>
            </div>
          </div>
        </div> -->
        <div class="list" v-if="len > 0">
          <span>优惠券</span>
          <span>{{ couPonData.coupon_template_info && couPonData.coupon_template_info.title }}</span>
        </div>
        <div class="list" v-if="len > 0">
          <span>数量</span>
          <span>{{ couPonData.code_info && couPonData.code_info.num }}张</span>
        </div>
        <van-button type="primary" style="width: 5.6rem" class="g-btn f-btncolor-blue" @click="searchCarList()">
          {{ isLogin ? '绑定车牌' : '请授权登录' }}</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import {Toast, Button, ImagePreview} from 'vant'
import {removeObjNull, browserName, urlEncode, urlParse, parseTime} from '@/utils/base'
import {setToken, getToken, setSmarkId, removeSmarkId, setAppid, getAppid} from '@/utils/auth'
import keyboard from '@/components/keyboard'
const {
  base: {getCarNumberListInStoping, getOrdersInfoByCarNumber, getAppidData, getCarOwnerHisTempCarNumber, carReimbursementRuleBindCarNumber},
} = api
export default {
  components: {
    Toast,
    Button,
    keyboard,
  },
  data() {
    return {
      baseName: process.env.VUE_APP_BASE_API,
      carNumAll: '', // 返回输入的车牌
      formData: {
        smartpark_id: '',
        from: browserName(),
        h5_url: encodeURIComponent(location.href),
      },
      errorImg: require('@/assets/images/notimg.png'),
      title: '出场预支付',
      alertShow: false, //提示弹窗背景
      isTips: false, //单纯提示弹窗
      title: '温馨提示', //提示弹窗文字
      tipsfont: '',
      isDelete: false, //删除提示弹窗
      deletefont: '是否确认删除',
      isChooseCar: false, //选择车牌弹窗
      chooseCarList: [], //选择车牌列表
      deleteHistoryId: 0, //确认删除历史ID
      isLogin: false,
      loading: false,
      parseTime: parseTime,
      historyCar: [],
      //输入键盘
      toastTxt: '',
      isToastShow: false,
      isStr: '20px',
      isEnd: '300px',
      ispickerDateShow: '-400px',
      dateTitle: '开始',
      beginTimeVal: '',
      overTimeVal: '',
      isFeeHourClick: true,
      checkbox: true,
      isDown: false,
      isUp: true,
      selected: null,
      isSelectx: false,
      isSelectl: false,
      key: '1',
      areaName: '',
      areaLetter: '',
      numOne: '',
      numTwo: '',
      numThree: '',
      numFour: '',
      numFive: '',
      numSix: '',
      isYue: false,
      keyboardShow: true,
      isA: false,
      isDisable: false,
      iscarId: true, //是否有车牌
      addedCarNum: '', //添加的车牌号码
      smartpark_id: '', //园区ID
      scancodeshow: false, //非微信和支付宝弹层，控制显示
      temporary_car_num: '',
      searchCaridShow: false,
      searchCaridList: [],
      couPonData: {},
      disabled: true,
    }
  },
  computed: {
    // 新能源选项勾选
    firstCheckbox() {
      if (this.carNumAll.replace(/\s+/g, '').length <= 7) {
        return false
      } else {
        return true
      }
    },

    isNoCarPay() {
      if (this.$route.query.passageway_id) {
        return true
      }
    },
    passageway_id() {
      if (this.$route.query.passageway_id) {
        return this.$route.query.passageway_id
      } else {
        return false
      }
    },
    len() {
      return Object.keys(this.couPonData).length
    },
  },
  watch: {},
  methods: {
    async searchCarImg(item) {
      if (this.historyCar.indexOf(item.car_number) == -1) {
        this.historyCar.push(item.car_number)
      }
      if (this.historyCar.length > 5) {
        //大于5个历史记录就删除第一个
        this.$delete(this.historyCar, 0) //删除数组里面对应的历史记录
      }
      // console.log('历史记录', this.historyCar)
      window.localStorage.setItem('lsCarId', this.historyCar)
      await this.gotoYpayment(item.car_number)
    },
    keyboardStatu(val) {
      this.disabled = val
    },
    lookImg(img) {
      this.validateImage(img ? img : '')
    },
    validateImage(imgurl) {
      let that = this
      var ImgObj = new Image()
      ImgObj.onload = function () {
        ImagePreview([imgurl])
      }
      ImgObj.onerror = function () {}
      ImgObj.src = imgurl
    },
    //影藏提示框
    hideAlert() {
      this.alertShow = false
    },
    //删除历史ID
    deleteHistoryOk() {
      this.alertShow = false //隐藏弹窗
      this.$delete(this.historyCar, this.deleteHistoryId) //删除数组里面对应的历史记录
      window.localStorage.setItem('lsCarId', this.historyCar) //重新缓存数组
    },
    // 车牌类型切换
    carTypeChange(val) {
      this.isNumOne = false
      this.isNumTwo = false
      this.isNumThree = false
      this.isNumFour = false
      this.isNumFive = false
      this.isNumSix = false
      if (!val) {
        // 切换到普通车牌
        if (this.numFour) {
          this.isNumFive = true
          this.key = 7
        }
      } else {
        // 切换到新能源车牌
        if (this.numFive) {
          this.isNumSix = true
          this.key = 8
        }
      }
    },
    // 车牌号第一位输入框选择
    btnClickYue() {
      this.isYue = true
      this.isA = false
      this.isUp = true
      this.isNumOne = false
      this.isNumTwo = false
      this.isNumThree = false
      this.isNumFour = false
      this.isNumFive = false
      this.isNumSix = false
      this.keyboardShow = true
      this.keyboard = 'txt'
      this.key = 1
    },
    // 键盘选择显示
    btnWordClick(rows, index, i) {
      this.selected = i
      if (this.key === 1) {
        if (this.areaName === '临') {
          this.areaLetter = ''
          this.numOne = ''
          this.numTwo = ''
          this.numThree = ''
          this.numFour = ''
          this.numFive = ''
          this.numSix = ''
        }
        this.areaName = i
        this.isSelectl = false
        document.getElementById('letter').click()
      } else if (this.key === 2) {
        this.areaLetter = i
        document.getElementById('numOne').click()
      } else if (this.key === 3) {
        this.numOne = i
        document.getElementById('numTwo').click()
      } else if (this.key === 4) {
        this.numTwo = i
        document.getElementById('numThree').click()
      } else if (this.key === 5) {
        this.numThree = i
        document.getElementById('numFour').click()
      } else if (this.key === 6) {
        this.numFour = i
        document.getElementById('numFive').click()
      } else if (this.key === 7) {
        this.numFive = i
        if (this.checkbox) {
          document.getElementById('numSix').click()
        }
      } else if (this.key === 8) {
        this.numSix = i
      }
      if (this.key === 7 || this.key === 8) {
        this.carNumBottom = ['W', 'X', 'Y', 'Z', '港', '澳', '学']
        this.noneBottom = ['']
      } else if (this.key === 3 || this.key === 4 || this.key === 5 || this.key === 6) {
        this.carNumBottom = ['W', 'X', 'Y', 'Z']
        this.noneBottom = ['', '', '', '']
      }
    },
    async gotoYpayment(num) {
      const {data, code} = await getOrdersInfoByCarNumber({
        car_number: num,
        smartpark_id: this.formData.smartpark_id,
        passageway_id: this.$route.query.passageway_id,
      })
      if (!code) {
        Toast(`无该车牌订单`)
        return
      }
      if (!Object.keys(data).length) {
        Toast(`无该车牌订单`)
        return
      }
      // console.log(44, data)
      this.chooseCarId(num)
    },
    searchCarList() {
      // return
      if (this.isLogin) {
        this.bindCar()
      } else {
        this.init()
      }
    },
    bindCar() {
      let true_numb = this.carNumAll[7] === ' ' ? 7 : 8
      if (this.carNumAll.replace(/\s+/g, '').length < true_numb) {
        Toast('请输入正确车牌')
        return
      }
      this.$refs.keyboard.completeClick()
      this.chooseCarId(this.carNumAll)
    },
    chooseCarId(num) {
      this.isChooseCar = false
      this.alertShow = false
      carReimbursementRuleBindCarNumber({
        rule_id: this.$route.query.rule_id,
        car_number: num.trim(),
      })
        .then(res => {
          if (res.code) {
            this.$router.push({
              path: '/rules',
              query: {
                status: 0,
              },
            })
          } else {
            Toast(res.msg)
          }
        })
        .catch(e => {
          Toast(e.data.msg)
        })
    },
    //点击显示删除提示
    deleteShow(i) {
      this.isChooseCar = false
      this.alertShow = true
      this.isDelete = true
      this.isTips = false
      this.deleteHistoryId = i //把键值保存起来，便于后面弹窗删除
    },
    async init() {
      let name = browserName()
      // console.log('navigator', navigator.userAgent.toLowerCase())
      const {smartpark_id = ''} = urlParse()
      this.isNumFour = true
      this.key = 6
      let strName = ''
      this.loading = true
      if (smartpark_id) {
        this.formData.smartpark_id = smartpark_id
        const {data} = await getAppidData({
          smartpark_id: this.formData.smartpark_id,
          from: name,
        })
        setSmarkId(smartpark_id)
        setAppid(data.app_id)
        if (getToken() && getToken() !== 'undefined') {
          this.isLogin = true
          this.loading = false
          let CarOwner = await getCarOwnerHisTempCarNumber()
          this.temporary_car_num = CarOwner.data.car_number
          // const data = await getCompanyCouponPoolInfoByCode({code: Array.isArray(this.$route.query.code) ? this.$route.query.code[1] : this.$route.query.code})
          // this.couPonData = data.data

          // this.$refs.footForm.phoneFn()
          //   this.getCarNum()
        } else {
          const {token, app_id = ''} = urlParse()
          if (token && app_id) {
            setToken(token, app_id)
            if (app_id === getAppid()) {
              this.isLogin = true
              this.loading = false
              let CarOwner = await getCarOwnerHisTempCarNumber()
              this.temporary_car_num = CarOwner.data.car_number
              // const data = await getCompanyCouponPoolInfoByCode({
              //   code: Array.isArray(this.$route.query.code) ? this.$route.query.code[1] : this.$route.query.code,
              // })
              // this.couPonData = data.data
              // if (this.$route.query.is_auto) {
              //   claimCouponAutoByCode({
              //     code: Array.isArray(this.$route.query.code) ? this.$route.query.code[1] : this.$route.query.code,
              //   }).then(res => {
              //     if (res.code) {
              //       this.$router.push({
              //         path: '/result',
              //         query: {
              //           status: 0,
              //         },
              //       })
              //     } else {
              //       Toast(res.msg)
              //     }
              //   })
              // }
            } else {
              strName = urlEncode(removeObjNull(this.formData))
              if (name === 'others') {
                Toast('请用支付宝或者微信打开授权')
              } else {
                window.location.href = process.env.VUE_APP_BASE_API + 'v1.oauth/redirectOauth?' + strName
              }
            }
          } else {
            strName = urlEncode(removeObjNull(this.formData))
            if (name === 'others') {
              Toast('请用支付宝或者微信打开授权')
            } else {
              // console.log('url', process.env.VUE_APP_BASE_API + 'v1.oauth/redirectOauth?' + strName)
              window.location.href = process.env.VUE_APP_BASE_API + 'v1.oauth/redirectOauth?' + strName
            }
          }
        }
      } else {
        Toast('园区获取错误')
      }
    },
    getlinshiCar() {
      getCarOwnerHisTempCarNumber().then(res => {
        this.temporary_car_num = res.data.car_number
      })
    },
    // 获取车牌列表
    async getCarNumList() {
      this.getlinshiCar()
      let num = this.numFour + this.numFive + this.numSix
      if (num.length < 3) {
        Toast('请填写车牌后三位')
      } else {
        const {data, msg, code} = await getCarNumberListInStoping({
          smartpark_id: this.formData.smartpark_id,
          car_number: num,
        })
        if (code === 1) {
          if (JSON.stringify(data) !== '{}') {
            // this.alertShow = true
            // this.isChooseCar = true
            // this.isDelete = false;
            // this.isTips = false;
            this.chooseCarList = data
            // let chooseData = data[0]
            this.searchCaridList = data
            if (this.chooseCarList.length === 1) {
              // this.searchCaridShow = false
              this.chooseCarList.map(ele => {
                let carId = ele.number
                if (this.historyCar.indexOf(carId) == -1) {
                  this.historyCar.push(carId)
                }
              })
              if (this.historyCar.length > 5) {
                //大于5个历史记录就删除第一个
                this.$delete(this.historyCar, 0) //删除数组里面对应的历史记录
              }
              // console.log('历史记录', this.historyCar)
              window.localStorage.setItem('lsCarId', this.historyCar)
              this.searchCaridShow = true
              // let num = this.chooseCarList[0].number
              // this.chooseCarId(num)
            } else {
              this.searchCaridShow = true
            }
          } else {
            Toast(`无该车牌订单`)
          }
        } else {
          this.alertShow = true
          this.isTips = true
          this.isChooseCar = false
          this.isDelete = false
          this.tipsfont = msg
          Toast(msg)
        }
      }
    },
  },
  created() {
    var lsHistory = window.localStorage.getItem('lsCarId')
    if (typeof lsHistory != 'undefined' && lsHistory != 'undefined' && lsHistory != null) {
      lsHistory = lsHistory.split(',')
      if (lsHistory[0] == '') {
        lsHistory = []
      }
      // console.log(11, lsHistory)
      this.historyCar = lsHistory //获取缓存的车牌，并且转成数组
      // console.log(88, this.historyCar)
    }
  },
  mounted() {
    this.init()
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {
    removeSmarkId()
  },
  activated() {},
}
</script>
<style lang="scss" scoped>
.prepay {
  padding-top: 0.1px;
  // background: #f2f2f2;
}
.claim-coupon {
  // height: 100vh;
}
.no-plate {
  margin-top: 10px;
  color: #1d72e8;
  text-align: center;
  font-size: 0.3rem;
}
.claim_title {
  text-align: center;
  font-size: 0.4rem;
}
.g-showcar-box {
  // padding: 47px;

  .ul-select {
    margin-top: 0.53333rem;
    margin-bottom: 1rem;
    max-height: 50vh;
    overflow-y: auto;
    padding: 0 0.4rem;
    li {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      border-bottom: 1px solid #ebebeb;
    }
    li:last-of-type {
      margin-bottom: 0px;
      border-bottom: 0;
    }
    .serial_num {
      font-size: 0.3rem;
      color: #878787;
      margin-right: 0.15rem;
    }
    .lt-img {
      padding: 0.2rem 0;
      width: 2rem;
      height: 1.3rem;
      border-radius: 0.13333rem;
      overflow: hidden;
      position: relative;
      ::v-deep .van-image__error {
        background: url('../assets/images/notimg.png') no-repeat center center;
        background-size: 90%;
      }
      ::v-deep .van-image__error-icon {
        display: none;
      }
    }
    .rt-ct {
      flex: 1;
      &-info {
        font-size: 0.22rem;
        color: #b1b6c4;
        line-height: 0.4rem;
        span {
          color: #b1b6c4;
          margin-left: 0.26667rem;
          .num {
            color: #000;
            margin-left: 0px;
            font-size: 0.3rem;
            i {
              color: #000;
              font-weight: bold;
            }
          }
        }
        .navigation {
          display: block;
          width: 1rem;
          height: 0.4rem;
          line-height: 0.4rem;
          text-align: center;
          border: 0.01333rem solid #000;
          border-radius: 0.06667rem;
          color: #000;
        }
      }
      &-mg {
        margin-bottom: 0.2rem;
        margin-top: 0.2rem;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .prompt-info {
    position: absolute;
    width: 100%;
    bottom: -1rem;
    font-size: 0.23rem;
    line-height: 0.5rem;
    text-align: center;
    background-image: -webkit-linear-gradient(bottom, #cfd9df 0%, #e2ebf0 100%);
    background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
  }
}
.list {
  display: flex;
  justify-content: space-between;
  font-size: 0.23rem;
  line-height: 0.6rem;
}
.list:nth-of-type(1) {
  margin-top: 0.2rem;
}
</style>
